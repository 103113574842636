import styles from "./Main.module.scss";
import { Sidebar } from "components";

const Main = () => {
  return (
    <div className={styles.wrapper}>
      <Sidebar />
    </div>
  );
};
export { Main };
